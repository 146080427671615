import Blake from "./blake.jpg";
import Dawn from "./dawn.png";
import Jaime from "./jaime.jpg";
import Shweta from "./shweta.jpg";
import Kayla from "./Kayla.jpg"
import Shiming from "./Shiming.png";
import Justin from "./justin.png";
import Randi from "./randi.jpg";
import Koffison from "./koffison.png";
import Nima from "./nima.jpeg";
import Amtul from "./amtul.png";
import Javaughn from "./javaughn.png";
// import Kenneth from "./kenneth.jpg";
// import Majestic from "./majestic.png";
// import Don from "./don.jpeg";
// import Alex from "./alex.jpg";
// import Jennifer from "./Jennifer.png";
// import Nanami from "./Nanami.png";
// import Chelsea from "./Chelsea.jpeg";
// import Israel from "./Israel.jpeg";
// import Robert from "./Robert.jpeg";
// import Muzaffar from "./Muzaffar.jpeg";
// import Christopher from "./Christopher.jpeg";
const profiles = [Blake, Dawn, Jaime, Shweta, Kayla, Shiming, Justin, Randi, Koffison, Nima, Amtul, Javaughn];

export default profiles;
